import { GENRE_HOME_PAGE_TITLE } from './constants';

export const buildGenreHomePageTitle = ({
  currentPath,
  navigationTitle,
  viewTitle,
}: {
  currentPath?: string | null;
  navigationTitle?: string;
  viewTitle?: string;
}) => {
  if (!currentPath) {
    return GENRE_HOME_PAGE_TITLE['/webtoon/recommendation'];
  }

  if (Object.keys(GENRE_HOME_PAGE_TITLE).includes(currentPath)) {
    return GENRE_HOME_PAGE_TITLE[currentPath];
  }

  const fallbackTitle = navigationTitle || viewTitle || '';
  const suffix = '리디';

  return [fallbackTitle, suffix].filter(Boolean).join(' - ');
};
